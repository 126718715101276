/* ********* Getters ********* */
//DRAWER
export const GET_DRAWER = "drawer/DRAWER";

/* ********* Mutations ********* */

//DRAWER
export const MUTATE_DRAWER = "drawer/MUTATE_DRAWER";

/* ********* Actions ********* */

//DRAWER
export const ACTION_DRAWER = "drawer/ACTION_DRAWER";

// /* ********* Getters ********* */
// //DRAWER
export const GET_DRAWER_FRONT = "drawerFront/DRAWER";

/* ********* Mutations ********* */

//DRAWER
export const MUTATE_DRAWER_FRONT = "drawerFront/MUTATE_DRAWER";

/* ********* Actions ********* */

//DRAWER
export const ACTION_DRAWER_FRONT = "drawerFront/ACTION_DRAWER";

export const dialogForm = {
  FORM_GET: "FORM_GET",
  FORM_LIST_GET: "FORM_LIST_GET",
  FORM_MUTATE: "FORM_MUTATION",
  FORM_LIST_MUTATE: "FORM_LIST_MUTATE",
  FORM_ACTION: "FORM_ACTION"
};

export const storeRegisterForm = {
  REGISTER_FORM_GET: "REGISTER_FORM_GET",
  REGISTER_FORM_MUTATE: "REGISTER_FORM_MUTATE",
  REGISTER_FORM_ACTION: "REGISTER_FORM_ACTION"
};

export const paginationData = {
  getters: {
    PAGINATION_GET: "PAGINATION_GET"
  },

  mutations: {
    PAGINATION_MUTATE: "PAGINATION_MUTATE"
  },

  actions: {
    PAGINATION_ACTION: "PAGINATION_ACTION"
  }
};

export const browseServiceData = {
  getters: {
    BROWSE_SERVICE_GET: "BROWSE_SERVICE_GET"
  },

  mutations: {
    BROWSE_SERVICE_MUTATE: "BROWSE_SERVICE_MUTATE"
  },

  actions: {
    BROWSE_SERVICE_ACTION: "BROWSE_SERVICE_ACTION"
  }
};

export const HandleEmployerToLogin = {
  getters: {
    HANDLE_EMPLOYER_TO_LOGIN_GET: "HANDLE_EMPLOYER_TO_LOGIN_GET"
  },

  mutations: {
    HANDLE_EMPLOYER_TO_LOGIN_MUTATE: "HANDLE_EMPLOYER_TO_LOGIN_MUTATE"
  },

  actions: {
    HANDLE_EMPLOYER_TO_LOGIN_ACTION: "HANDLE_EMPLOYER_TO_LOGIN_ACTION"
  }
};

export const BrowseProjectData = {
  getters: {
    BROWSE_PROJECT_GET: "BROWSE_PROJECT_GET"
  },

  mutations: {
    BROWSE_PROJECT_MUTATE: "BROWSE_PROJECT_MUTATE"
  },

  actions: {
    BROWSE_PROJECT_ACTION: "BROWSE_PROJECT_ACTION"
  }
};

export const avatarManagement = {
  getters: {
    AVATAR_MANAGEMENT_GET: "AVATAR_MANAGEMENT_GET"
  },

  mutations: {
    AVATAR_MANAGEMENT_MUTATE: "AVATAR_MANAGEMENT_MUTATE"
  },

  actions: {
    AVATAR_MANAGEMENT_ACTION: "AVATAR_MANAGEMENT_ACTION"
  }
};

export const firstNameAndLastNameManagement = {
  getters: {
    NAME_MANAGEMENT_GET: "NAME_MANAGEMENT_GET"
  },

  mutations: {
    NAME_MANAGEMENT_MUTATE: "NAME_MANAGEMENT_MUTATE"
  },

  actions: {
    NAME_MANAGEMENT_ACTION: "NAME_MANAGEMENT_ACTION"
  }
};

export const skeletonShow = {
  getters: {
    SKELETON_GET: "SKELETON_GET"
  },

  mutations: {
    SKELETON_MUTATE: "SKELETON_MUTATE"
  },

  actions: {
    SKELETON_ACTION: "SKELETON_ACTION"
  }
};
